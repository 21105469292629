import React from 'react';
import Background from '../Assets/HeroBG.png';
import { useHistory } from 'react-router-dom';

const HeroComponent = () => {

    const history = useHistory();

    const handleButtonClick = () => {
        history.push('/plans');
    };

    const imageUrl = `url(${Background})`;

    return (
        <div
            className="relative image-box h-[40vh] sm:h-[70vh] w-full flex flex-col items-center justify-center"
            style={{ backgroundImage: imageUrl, backgroundSize: 'cover', backgroundPosition: 'center' }}
        >
            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-70"></div>

            <div className="relative z-10 inner-content text-white text-center flex-col flex">
                <h2 className="text-3xl sm:text-6xl max-w-3xl font-bold mb-8">
                    Make the switch, and stay
                    connected at all times
                </h2>
                <div className='w-3/5 flex flex-col sm:flex-row justify-center self-center'>
                    <button onClick={() => handleButtonClick()} className="bg-[#ef476f] text-white px-12 py-4 rounded font-display font-medium hover:bg-[#a993e6] transition duration-300 mb-4">
                        View our packages
                    </button>
                </div>
            </div>
        </div>
    );
}

export default HeroComponent;
