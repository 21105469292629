import axios from "axios";
import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { MdChevronLeft } from "react-icons/md";
import { CircleFlag } from 'react-circle-flags';
import { trefoil } from 'ldrs';
import franceBG from '../Assets/france.png'
import italyBG from '../Assets/italy.png'
import japanBG from '../Assets/japan.png'
import spainBG from '../Assets/spain.png'
import canadaBG from '../Assets/canada.png'
import euBG from '../Assets/eu.png'
import ukBG from '../Assets/uk.png'
import USBG from '../Assets/us.png'
import auBG from '../Assets/aus.png'
import logo from '../Assets/Logo.png'
import card from '../Assets/card.png'
import { useAuth } from "../Providers/AuthProvider";

trefoil.register();

const PlansFull = () => {
    const location = useLocation();
    const { country } = location.state;
    const history = useHistory();
    const { authData } = useAuth();
    const [packages, setPackages] = useState([]);
    const [selectedPackageIndex, setSelectedPackageIndex] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleCheckout = (planId) => {
        if (!authData.token) {
            localStorage.setItem('postLoginRedirect', `/checkout/${planId}`);
            history.push('/login');
        } else {
            console.log('Dashboard')
        }
    }

    const countrySettings = {
        'fr': { bgColor: '#C50225', greeting: 'Bienvenue !', bgImage: franceBG },
        'it': { bgColor: '#6da544', greeting: 'Benvenuta !', bgImage: italyBG },
        'us': { bgColor: '#0052b4', greeting: 'Greetings !', bgImage: USBG },
        'jp': { bgColor: '#d80027', greeting: 'いらっしゃいませ !', bgImage: japanBG },
        'au': { bgColor: '#0044CC', greeting: 'Welcome !', bgImage: auBG },
        'es': { bgColor: '#bb0425', greeting: 'Bienvenida !', bgImage: spainBG },
        'ca': { bgColor: '#ffd428', greeting: 'Greetings !', bgImage: canadaBG },
        'gb': { bgColor: '#d80027', greeting: 'Greetings !', bgImage: ukBG },
    };

    const getDefaultSettings = () => ({
        bgColor: '#64aaf5',
        greeting: 'Greetings !',
        bgImage: euBG,
    });

    const getCountrySettings = (isoCode) => {
        return countrySettings[isoCode.toLowerCase()] || getDefaultSettings();
    };

    const { bgColor, greeting, bgImage } = getCountrySettings(country.iso);


    useEffect(() => {
        setLoading(true);
        setPackages([]);
        const GetDataPackages = async () => {
            try {
                const response = await axios.get(`https://api.esim-go.com/v2.3/catalogue?countries=${country.iso}&api_key=vv4SSBBqH81JBFC0yEl2poSPrd-fO-_lr34s42FC`);
                setPackages(response.data.bundles);
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };
        GetDataPackages();
    }, [country.iso]);

    const handleSelectPackage = (index) => {
        setSelectedPackageIndex(index);
    };

    const backgroundStyle = {
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    };

    return (
        <div className="mx-auto mb-10">
            <div className="h-[50vh] flex flex-col justify-center relative bg-cover bg-center">
                <div className="absolute inset-0 z-0 opacity-70" style={backgroundStyle}></div>

                <div className="absolute inset-0" style={{ backgroundColor: bgColor, mixBlendMode: 'multiply', zIndex: 10 }}></div>

                <div className="text-center relative z-20">
                    <p className="text-white font-bold text-4xl">{country.name}</p>
                </div>

                <div className="relative z-10 mx-auto mt-10 shadow-2xl" style={{ width: 'fit-content' }}>
                    <img src={card} alt="Card" />

                    <div className="absolute inset-0 flex flex-col justify-between p-4">
                        <div className="self-start">
                            <p className="text-white text-3xl font-bold">{greeting}</p>
                        </div>

                        <div className="flex justify-between w-full mt-4">
                            <img src={logo} alt="Logo" className="h-10" />
                            <CircleFlag countryCode={country.iso.toLowerCase()} width="32" />
                        </div>
                    </div>
                </div>

            </div>
            <div className="px-4 py-6">
                <p className="font-display text-base font-semibold">Data-only Packages</p>
                {!loading ? packages.map((packageItem, index) => (
                    <div onClick={() => handleSelectPackage(index)} key={index} className={`mt-6 ${index === selectedPackageIndex ? 'border-[#ef476f] bg-[#fdedf1]' : 'border-gray-200'} rounded-xl border border-solid cursor-pointer`}>
                        <div className="p-5 border rounded-xl">
                            <div className="flex flex-row items-center">
                                <CircleFlag countryCode={packageItem.countries[0].iso.toLowerCase()} width="27" />
                                <p className="pl-1 font-display text-sm text-gray-54">{packageItem.countries[0].name}</p>
                                <input
                                    type="checkbox"
                                    className="ml-auto"
                                    checked={index === selectedPackageIndex}
                                    onChange={() => handleSelectPackage(index)}
                                />
                            </div>
                            <div className="flex flex-row justify-between mt-4 font-display text-base font-medium leading-normal text-gray-54 transform -translate-y-[2px]">
                                <div className="flex flex-row gap-3">
                                    <p>{packageItem.dataAmount / 1000} GB</p>
                                    <p>{packageItem.duration} Days</p>
                                </div>
                                <p className="text-orange-500">USD {packageItem.price.toFixed(2)}</p>
                            </div>
                        </div>
                    </div>
                )) : (
                    <div className="col-span-2 flex justify-center items-center">
                        <l-trefoil
                            size="40"
                            stroke="4"
                            stroke-length="0.15"
                            bg-opacity="0.1"
                            speed="1.4"
                            color="black"
                        ></l-trefoil>
                    </div>
                )}
            </div>
            {selectedPackageIndex !== null && (
                <div className="fixed bottom-0 left-0 w-full bg-[#ffffff]  py-3 px-8 rounded-t-md top-shadow">
                    <div className="flex flex-col justify-between mb-4">
                        <p className="font-display font-semibold text-base">{packages[selectedPackageIndex].price.toFixed(2)} USD </p>
                        <div className="flex flex-row gap-4 pt-2">
                            <p className="font-display font-semibold text-sm">{packages[selectedPackageIndex].dataAmount / 1000} GB</p>
                            <p className="font-display font-semibold text-sm">{packages[selectedPackageIndex].duration} Days</p>
                        </div>
                    </div>
                    <button onClick={() => handleCheckout("eu-global-4gb")} className="w-full bg-[#ef476f] text-white py-2 rounded-lg">
                        Buy Now
                    </button>
                </div>
            )}
        </div>
    );
};

export default PlansFull;
