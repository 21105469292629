import React from 'react';
import HeroComponent from '../Components/Hero';
import PopularDestinations from '../Components/PopularDestinations';
import Benefits from '../Components/Benefits';
import SetUpSteps from '../Components/SetUp';
import FAQ from '../Components/FAQ';

const LandingPage = () => {
    return (
        <div className=''>
            <HeroComponent />
            <PopularDestinations />
            {/* <Benefits/> */}
            <SetUpSteps/>
            <FAQ/>
        </div>
    );
};

export default LandingPage;
