import { MdChevronRight } from "react-icons/md";
import React from 'react'
import countriesData from "../../Data/PopularDestinationsData";
import { CircleFlag } from 'react-circle-flags'
import { useHistory } from "react-router-dom";

const SingleRegions = () => {

    let history = useHistory()

    const handleOnClick = () => {
        history.push('/plans')
    }

    const handleItemClick = (country) => {
        history.push({
          pathname: `/plans/${country.name}`,
          state: { country },
        });
    }

    return (
        <div className="flex flex-col mt-8">
            <div className="flex flex-row items-center justify-between mb-4">
                <p className="font-display text-lg font-semibold text-[#14183e]">Single Regions</p>
                <div onClick={() => handleOnClick()} className="flex flex-row items-center">
                    <p className='text-blue-500 font-display text-base'>More</p>
                    <MdChevronRight />
                </div>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                {countriesData ? countriesData.map((item, index) => (
                    <div onClick={() => handleItemClick(item)} key={index} className="p-4 border border-gray-200 rounded-lg cursor-pointer">
                        <div className="flex flex-row items-center">
                            <CircleFlag countryCode={item.iso.toLowerCase()} width="32" />
                            <div className="flex flex-col ml-4 w-20 sm:w-32"> {/* Adjusted width here */}
                                <p className="text-base font-display truncate">{`${item.name}`}</p>
                            </div>
                        </div>
                    </div>
                )) : 'Loading...'}
            </div>
        </div>
    )
}

export default SingleRegions;
