import React, { useState, useEffect } from "react";
import axios from "axios";
import Select, { Props } from "react-select";
import { CircleFlag } from 'react-circle-flags'
import { trefoil } from 'ldrs'
import { useHistory } from "react-router-dom";

trefoil.register()

const Plans = () => {

  let history = useHistory()

  const [selectValue, setSelectValue] = useState({ label: "Europe", value: "Europe%2B" });
  const [uniqueCountries, setUniqueCountries] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    setUniqueCountries([])
    const GetDataPackages = async () => {
      try {
        const response = await axios.get(`https://api.esim-go.com/v2.3/catalogue?countries=${selectValue.value}&api_key=vv4SSBBqH81JBFC0yEl2poSPrd-fO-_lr34s42FC`);
        console.log(response.data.bundles);

        const countriesMap = new Map();
        response.data.bundles.forEach(bundle => {
          bundle.roamingEnabled.forEach(country => {
            countriesMap.set(country.iso, { name: country.name, iso: country.iso });
          });
        });

        setUniqueCountries([...countriesMap.values()]);
        setLoading(false)
      } catch (err) {
        setError(err);
        setLoading(false)
      }
    };
    GetDataPackages();
  }, [selectValue]);

  const handleItemClick = (country) => {
    console.log(country)
    history.push({
      pathname: `/plans/${country.name}`,
      state: { country },
    });
  }

  const sampleOptions = [
    {
      label: "Region",
      options: [
        {
          label: "Europe",
          value: "Europe%2B",
        },
        {
          label: "Asia",
          value: "Asia",
        },
        {
          label: "Africa",
          value: "Africa",
        },
        {
          label: "Middle East",
          value: "Middle East",
        },
        {
          label: "North America",
          value: "North America",
        },
        {
          label: "South America",
          value: "South America",
        },
        {
          label: "Oceania",
          value: "Oceania",
        },

      ],
    },
  ];



  return (
    <div className="w-10/12 mx-auto py-10">
      <div className="text-center max-w-md mx-auto">
        <p className="font-display text-5xl text-[#14183e] font-bold mb-8">
          Choose your plan
        </p>
        <p className="font-display text-base text-[#5e6282] font-normal mb-4">
          Please <span className="font-bold text-[#14183e]">dial *#06#</span> to
          check device compatibility, if EID exists then your device is
          compatible
        </p>
      </div>
      <Select
        value={selectValue}
        options={sampleOptions}
        isMulti={false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        onChange={(selectedOption) => setSelectValue(selectedOption)}
      />

      <div className="grid grid-cols-2 md:grid-cols-1 gap-4 mt-8">
        {!loading ? uniqueCountries.map((item, index) => (
          <div onClick={() => handleItemClick(item)} key={index} className="p-4 border border-gray-200 rounded-lg">
            <div className="flex flex-row items-center">
              <CircleFlag countryCode={item.iso.toLowerCase()} width="32" />
              <div className="flex flex-col ml-4 w-20"> {/* Adjusted width here */}
                <p className="text-base font-display truncate">{`${item.name}`}</p>
              </div>
            </div>
          </div>
        )) :(
          <div className="col-span-2 flex justify-center items-center"> {/* Centering the loader */}
            <l-trefoil
              size="40"
              stroke="4"
              stroke-length="0.15"
              bg-opacity="0.1"
              speed="1.4"
              color="black"
            ></l-trefoil>
          </div>
        )}
      </div>
    </div>
  );
};

export default Plans;
