import React from 'react';
import PopularDestinationsData from '../Data/PopularDestinationsData';
import { useHistory } from 'react-router-dom';
import MultiRegions from './Packages/MultiRegions';
import SingleRegions from './Packages/SingleRegions';

const PopularDestinations = () => {
    return (
        <div className='w-10/12 sm:w-3/5 mx-auto py-10'>

            <div className='text-center max-w-md mx-auto'>
                <p className='font-display text-base sm:text-lg text-[#5e6282] font-semibold mb-2 sm:mb-4'>Here are some of our bestsellers</p>
                <p className='font-display text-3xl sm:text-5xl text-[#14183e] font-bold mb-8'>Travel carefree with eSIMBolt!</p>
            </div>

            <MultiRegions/>
            <SingleRegions/>
        </div>
    );
};

export default PopularDestinations;
