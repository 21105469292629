import { useState } from 'react';
import Logo from '../Assets/Logo.png';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../Providers/AuthProvider';

const LoginScreen = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { createAuthData } = useAuth();
    let history = useHistory()

    const handleLogins = async () => {
        try {
            const response = await axios.post('https://e-sim-api.vercel.app/api/sims/login', {
                email,
                password
            });

            console.log(response.data)
            console.log(response.data.success)

            if (response.data.success === true) {
                createAuthData(
                    response.data.authtoken,
                    response.data.userId,
                    response.data.email,
                    response.data.name
                );
            }

            const redirectPath = localStorage.getItem('postLoginRedirect');
            if (redirectPath) {
                history.push(redirectPath);
                localStorage.removeItem('postLoginRedirect');
            } else {
                history.push('/dashboard');
            }

        } catch (error) {
            if (error.response) {
                if (error.response.status === 400) {
                    console.error('Validation errors:', error.response.data.errors);
                }
            } else if (error.request) {
                console.error('No response received:', error.request);
            } else {
                console.error('Error setting up the request:', error.message);
            }
        }
    };


    return (
        <div className="flex my-10 justify-center items-center flex-col">
            <div className="sm:w-full sm:max-w-sm">
                <img className="mx-auto h-20 w-auto" src={Logo} alt="eSimBolt Logo" />
            </div>

            <div className="mt-10 w-3/4 sm:w-full sm:max-w-sm">
                <div className="space-y-6">
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email address</label>
                        <div className="mt-2">
                            <input className='w-full border py-2 focus:border-[#ef476f] font-display outline-none rounded px-2' onChange={(e) => setEmail(e.target.value)} id="email" name="email" type="email" autoComplete="email" />
                        </div>
                    </div>

                    <div>
                        <div className="flex items-center justify-between">
                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">Password</label>
                            <div className="text-sm">
                            </div>
                        </div>
                        <div className="mt-2">
                            <input className='w-full border outline-none py-2 px-2 rounded font-display focus:border-[#ef476f]' onChange={(e) => setPassword(e.target.value)} id="password" name="password" type="password" autoComplete="password" />
                        </div>
                    </div>
                    <div>
                        <button onClick={() => handleLogins()} className='w-full mb-4 py-3 rounded bg-[#ef476f] text-white'>Login In</button>
                        <p onClick={() => history.push('/register')}>Need an account? Signup</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginScreen
