import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NavigationMenu from './Components/NavigationMenu';
import LandingPage from './Screens/LandingPage'
import Plans from './Screens/Plans'
import PlansFull from './Screens/PlansFull'
import Login from './Screens/Login'
import Register from './Screens/Register'
import Checkout from './Screens/Checkout'
import Dashboard from './Screens/Dashboard'
import { AuthProvider } from './Providers/AuthProvider';

function App() {
  return (
    <AuthProvider>
      <Router>
        <NavigationMenu />
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/plans" component={Plans} />
          <Route exact path="/plans/:id" component={PlansFull} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/checkout/:id" component={Checkout} />
          <Route exact path="/dashboard" component={Dashboard} />
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;
