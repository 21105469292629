import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Import the Chevron icons from react-icons

const FAQData = {
  "faq": [
    {
      "question": "What is eSimBolt?",
      "answer": "eSimBolt is a service that provides international eSIM data plans, allowing travelers to stay connected in multiple countries without the need for physical SIM cards."
    },
    {
      "question": "How does eSimBolt work?",
      "answer": "eSimBolt offers eSIMs that can be downloaded to compatible devices. You choose a data plan, scan a QR code, and activate your eSIM to access data in your destination country."
    },
    {
      "question": "Which devices are compatible with eSimBolt eSIMs?",
      "answer": "eSimBolt eSIMs are compatible with a wide range of eSIM-enabled devices, including smartphones, tablets, and wearables. Check our compatibility list for specific models."
    },
    {
      "question": "Where can I use eSimBolt eSIMs?",
      "answer": "eSimBolt offers eSIM data plans for various countries and regions worldwide. You can check our coverage map to see the list of supported destinations."
    },
    {
      "question": "Can I use my eSimBolt eSIM with multiple devices?",
      "answer": "No, eSimBolt eSIMs are designed for use with a single eSIM-compatible device. Each device requires its own eSIM."
    },
    {
      "question": "What happens if I run out of data on my eSimBolt eSIM?",
      "answer": "If you run out of data on your eSimBolt eSIM, you can easily purchase additional data by visiting our website and selecting a top-up plan for your destination."
    },
    {
      "question": "How do I activate my eSimBolt eSIM?",
      "answer": "To activate your eSimBolt eSIM, follow the instructions provided in the email you receive after purchase. Typically, you'll need to scan the QR code on your device."
    },
    {
      "question": "Is eSimBolt secure and reliable?",
      "answer": "Yes, eSimBolt takes security and reliability seriously. Our eSIMs are sourced from reputable carriers, ensuring a stable and secure data connection."
    },
  ]
};

const FAQ = () => {
  const [openItem, setOpenItem] = useState(null);

  const handleItemClick = (index) => {
    if (openItem === index) {
      setOpenItem(null); // Close the item if it's already open
    } else {
      setOpenItem(index); // Open the clicked item
    }
  };

  return (
    <div className="w-10/12 mx-auto py-10">
      <div className='max-w-sm m-auto text-center'>
        <h2 className="text-xl font-display font-semibold mb-2 text-[#5e6282]">eSimBolt</h2>
        <h2 className="text-4xl font-display font-bold mb-4 text-[#14183e]">Frequently Asked Questions</h2>
      </div>
      <div className='mt-10'>
        {FAQData.faq.map((item, index) => (
          <div
            key={index}
            className="mb-6 border border-gray-300 rounded p-4"
          >
            <div
              className="flex justify-between items-center font-bold text-[#14183e] text-lg font-display cursor-pointer"
              onClick={() => handleItemClick(index)}
            >
              {item.question}
              <div className="transform transition-transform duration-300">
                {openItem === index ? (
                  <FaChevronUp className="h-6 w-6 text-[#14183e]" />
                ) : (
                  <FaChevronDown className="h-6 w-6 text-[#14183e]" />
                )}
              </div>
            </div>
            {openItem === index && (
              <div className="text-gray-600 mt-2 font-display">{item.answer}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};


export default FAQ;
