import { MdChevronRight } from "react-icons/md";
import world from '../../Assets/world.png'
import React from 'react'
import axios from "axios";
import { trefoil } from 'ldrs'
trefoil.register()

const MultiRegions = () => {

    const [multiRegions, setMultiRegions] = React.useState();
    const [error, setError] = React.useState();

    const processBundles = (bundles) => {
        const lowestPricePerRegion = {};
        const roamingCountPerRegion = {};
    
        bundles.forEach(bundle => {
            const price = bundle.price || Infinity;
    
            bundle.roamingEnabled.forEach(country => {
                const region = country.region;
                if (!roamingCountPerRegion[region]) {
                    roamingCountPerRegion[region] = bundle.roamingEnabled.length;
                }
            });
    
            bundle.countries.forEach(country => {
                const region = country.region;
                if (!lowestPricePerRegion[region] || price < lowestPricePerRegion[region].price) {
                    lowestPricePerRegion[region] = { country: country.name, price };
                }
            });
        });
    
        return Object.entries(lowestPricePerRegion).map(([region, details]) => ({
            [region]: {
                ...details,
                roamingCount: roamingCountPerRegion[region] || 0
            }
        }));
    };
    
    
    
    

    React.useEffect(() => {
        const GetDataPackages = async () => {
            try {
                const response = await axios.get(`https://api.esim-go.com/v2.3/catalogue?countries=Global%2CEurope%2B%2CAsia%2CAfrica%2CMiddle+East%2CNorth+America%2COceania&api_key=vv4SSBBqH81JBFC0yEl2poSPrd-fO-_lr34s42FC`);
                console.log(response.data.bundles)
                const processedData = processBundles(response.data.bundles);
                setMultiRegions(processedData);
            } catch (err) {
                setError(err);
            }
        }
        if (!multiRegions) {
            GetDataPackages();
          }
        
    }, [multiRegions])

    return (
        <div className="flex flex-col">
            <div className="flex flex-row items-center justify-between mb-4">
                <p className="font-display text-lg font-semibold text-[#14183e]">Multi Regions</p>
                <div className="flex flex-row items-center">
                    <p className='text-blue-500 font-display text-base'>More</p>
                    <MdChevronRight />
                </div>
            </div>
            <div className="grid md:grid-cols-3 grid-cols-1 gap-4">

                {multiRegions ? multiRegions.slice(0, 3).map((item, index) => {
                    const regionKey = Object.keys(item)[0];
                    const regionData = item[regionKey];
                    return (
                        <div key={index} className="p-4 border border-gray-200 rounded-lg">
                            <div className="flex flex-row items-center">
                                <img src={world} className="w-8 h-8" />
                                <div className="flex flex-col ml-4">
                                    <p className="text-base font-display">{`${item[Object.keys(item)[0]].country} (${regionData.roamingCount} Countries)`}</p>
                                    <p className="text-sm text-gray-500 font-display ">From <span className="text-[#ef476f] ml-1 font-display font-semibold text-base">USD {item[Object.keys(item)[0]].price.toFixed(2)}</span></p>
                                </div>
                            </div>
                        </div>
                    )
                }) :(
                    <div className="col-span-2 flex justify-center items-center"> {/* Centering the loader */}
                      <l-trefoil
                        size="40"
                        stroke="4"
                        stroke-length="0.15"
                        bg-opacity="0.1"
                        speed="1.4"
                        color="black"
                      ></l-trefoil>
                    </div>
                  )}
            </div>
            {error && <p>There was an error loading the data.</p>}
        </div>
    )
}

export default MultiRegions