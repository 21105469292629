import { useState } from 'react';
import Logo from '../Assets/Logo.png';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const Registration = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName , setFirstName] = useState('')
  const [lastName , setLastName] = useState('')

  const history = useHistory()


  const handleLogins = async () => {
    console.log('called api');
  
    try {
      const response = await axios.post('https://jace-medical-ambulance-api.vercel.app/api/staff/policies/login', {
        email,
        password
      });
  
      if (response.data.success === true) {
        // createAuthData(
        //   response.data.token,
        //   response.data.userId,
        //   response.data.email,
        //   response.data.role,
        //   response.data.status,
        //   response.data.name
        // );
      }
  
    } catch (error) {
      if (error.response) {
        console.error('Server error:', error.response.data);
        console.error('Status code:', error.response.status);
        console.error('Headers:', error.response.headers);
        if (error.response.status === 400) {
          console.error('Validation errors:', error.response.data.errors);
        }
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error setting up the request:', error.message);
      }
    }
  };


  return (
    <div className="flex my-10 justify-center items-center flex-col">
      <div className="sm:w-full sm:max-w-sm">
        <img className="mx-auto h-20 w-auto" src={Logo} alt="eSimBolt Logo" />
      </div>

      <div className="mt-10 w-3/4 sm:w-full sm:max-w-sm">
        <div className="space-y-6">
        <div>
            <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900">First Name</label>
            <div className="mt-2">
              <input className='w-full border py-2 focus:border-[#ef476f] font-display outline-none rounded px-2' onChange={(e) => setFirstName(e.target.value)} id="firstName" name="firstName" type="text" autoComplete="given-name" />
            </div>
          </div>
          <div>
            <label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900">Last Name</label>
            <div className="mt-2">
              <input className='w-full border py-2 focus:border-[#ef476f] font-display outline-none rounded px-2' onChange={(e) => setLastName(e.target.value)} id="lastName" name="lastName" type="text" autoComplete="family-name" />
            </div>
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email address</label>
            <div className="mt-2">
              <input className='w-full border py-2 focus:border-[#ef476f] font-display outline-none rounded px-2' onChange={(e) => setEmail(e.target.value)} id="email" name="email" type="email" autoComplete="email" />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">Password</label>
              <div className="text-sm">
              </div>
            </div>
            <div className="mt-2">
              <input className='w-full border outline-none py-2 px-2 rounded font-display focus:border-[#ef476f]' onChange={(e) => setPassword(e.target.value)} id="password" name="password" type="password" autoComplete="password" />
            </div>
          </div>
          <div>
            <button onClick={() => handleLogins()} className='w-full mb-4 py-3 rounded bg-[#ef476f] text-white'>Register</button>
            <p onClick={() => history.push('/login')}>Need an account? Signup</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Registration
