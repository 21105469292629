import React from 'react';
import { FaRegCheckCircle, FaQrcode, FaSignal } from 'react-icons/fa';

const SetUpSteps = () => {
  const setupSteps = [
    {
      title: 'Choose a plan',
      description: 'Choose an Unlimited or Prepaid eSIM data plan in the country where you need connectivity.',
      icon: <FaRegCheckCircle size={30} />,
      color: 'bg-red-500',
    },
    {
      title: 'Scan QR code',
      description: 'Use your smartphone or eSIM-compatible device to scan the QR code we send you via email.',
      icon: <FaQrcode size={30} />,
      color: 'bg-blue-500',
    },
    {
      title: 'Connect to 4G or 5G',
      description: 'Follow the prompts on your device to add your new international data plan, and say goodbye to roaming fees!',
      icon: <FaSignal size={30} />,
      color: 'bg-green-500',
    },
  ];

  return (
    <div className='w-3/4 mx-auto py-10'>
      <div className='text-center max-w-md mx-auto'>
        <p className='font-display text-lg text-[#5e6282] font-semibold mb-4'>eSimBolt</p>
        <p className='font-display text-5xl text-[#14183e] font-bold mb-8'>How to set up your eSIM?</p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-10">
        {setupSteps.map((step, index) => (
          <div key={index} className={`bg-white p-4 rounded shadow-md flex flex-col items-center`}>
            <div className={`h-12 w-12 text-white rounded ${step.color} flex items-center justify-center my-6`}>
              {step.icon}
            </div>
            <h3 className="text-lg font-bold mb-2 text-center font-display text-[#14183e]">{step.title}</h3>
            <p className="text-sm text-gray-600 text-center">{step.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SetUpSteps;
