import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState({
    token: null,
    userId: null,
    email: null,
    name: null
  });

  useEffect(() => {
    
    const savedAuthData = JSON.parse(localStorage.getItem('authData'));
    if (savedAuthData) {
      setAuthData(savedAuthData);
    }
  }, []);

  const createAuthData = (token, userId, email, name) => {
    setAuthData({
      token,
      userId,
      email,
      name
    });

    localStorage.setItem('authData', JSON.stringify({
      token,
      userId,
      email,
      name
    }));
  };

  const logout = () => {
    setAuthData({
      token: null,
      userId: null,
      email: null,
      name: null
    });

    localStorage.removeItem('authData');
  };

  return (
    <AuthContext.Provider value={{ authData, createAuthData, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
