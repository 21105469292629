
const countriesData = [
    {
      "name": "France",
      "isoLogo": "https://flagsapi.com/FR/flat/64.png",
      "iso": "FR"
    },
    {
      "name": "Italy",
      "isoLogo": "https://flagsapi.com/IT/flat/64.png",
      "iso": "IT"
    },
    {
      "name": "Japan",
      "isoLogo": "https://flagsapi.com/JP/flat/64.png",
      "iso": "JP"
    },
    {
      "name": "United States",
      "isoLogo": "https://flagsapi.com/US/flat/64.png",
      "iso": "US"
    },
    {
      "name": "Australia",
      "isoLogo": "https://flagsapi.com/AU/flat/64.png",
      "iso": "AU"
    },
    {
      "name": "Spain",
      "isoLogo": "https://flagsapi.com/ES/flat/64.png",
      "iso": "ES"
    },
    {
      "name": "Canada",
      "isoLogo": "https://flagsapi.com/CA/flat/64.png",
      "iso": "CA"
    },
    {
      "name": "United Kingdom",
      "isoLogo": "https://flagsapi.com/GB/flat/64.png",
      "iso": "GB"
    }
  ];
  
  export default countriesData;
  